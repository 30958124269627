export default {
  headerTitle: 'Rollee Ride',
  footerTitle: 'Powered by',
  homePageTitle: 'Connectez vos comptes professionnels',
  homeGetStarted: 'C’est parti !',
  termsPageTitle: 'Votre autorisation est nécessaire pour accéder à vos données',
  termsDetailsHowItWorks: 'Comment ça marche ?',
  termsDetailsHowItWorksDescription: 'Nous accédons aux données de vos comptes professionnels pour vous permettre d\'accéder au service de votre choix.',
  termsDetailsDataProtection: 'Comment sont protégées mes données ?',
  termsDetailsDataProtectionDescription: 'Vos données sont cryptées. Vous pouvez retirer votre consentement à tout moment en contactant Rollee.',
  termsFooterToggleAccept: ' J\'ai lu et j\'accepte les',
  termsFooterToggleAcceptLink: 'Conditions Générales d\'Utilisation',
  termsFooterAllow: 'J’autorise',
  termsPrivacyPolicy: 'Politique de Confidentialité',
  termsPrivacyPolicyDescription_1: 'En cliquant sur J\'autorise vous permettez que vos données soient partagées avec',
  termsPrivacyPolicyDescription_2: 'et Rollee et vous acceptez notre',
  connectPageTitle: 'Quel est votre compte professionnel ?',
  inputSearchPlaceholder: 'Cherchez votre compte professionnel',
  logInTo: 'Se connecter à',
  username: 'Login',
  password: 'Mot de passe',
  email: 'Email',
  phone: 'Téléphone',
  emailPhone: 'Email ou Téléphone',
  defaultLogin: 'Login',
  secureAccess: 'Connexion sécurisée',
  dataProtection: 'Vos données sont cryptées et ne seront jamais partagées sans votre autorisation',
  dontSeeCompany: 'Vous ne trouvez pas votre plateforme ?',
  clickToContinue: 'Cliquez ici pour continuer',
  successTitle: 'Connecté !',
  connecting: 'Nous nous connectons à',
  pleaseWait: 'Cela peut prendre jusqu\'à 1 minute, soyez patient',
  dataSourceError: 'Une erreur s’est produite. Réessayez.',
  goBack: 'Retour',
  continue: 'Continuer',
  sureWantToExit: 'Êtes-vous sûr de vouloir quitter?',
  progressWillBeLost: 'Votre progression sera perdue',
  yesExit: 'Oui, quitter',
  noContinue: 'Non, continuer',
  connectedAccounts: 'Comptes connectés',
  suggested: 'Suggestions',
  addAccount: 'Ajouter un compte',
  done: 'Terminé',
  tryAgain: 'Réessayer',
  submit: 'Soumettre',
  getBackYouSoon: 'Merci. Nous vous contactons prochainement',
  enterCompanyName: 'Entrer le nom de l\'entreprise',
  errorConnectingDataSource: "Erreur de connexion. Veuillez réessayer plus tard.",
  termsOfUseUrl: 'https://fr.getrollee.com/terms-of-service-api',
  privacyPolicyUrl: 'https://fr.getrollee.com/privacy-policy-connect-api',
  searchCountriesPlaceholder: 'Rechercher un pays ou un code',
  codePlaceholder: 'Entrer le code',
  code: 'Entrer le code',
  send: 'Envoyer',
  loginWithFacebook: 'Connectez vous avec Facebook',
  facebookNotSupported: 'Désolé, la connexion via Facebook n\'est pas encore possible.',
  connectToOtherAccounts: 'Vous pouvez connecter vos autres comptes chauffeur.',
  close: 'Proche',
  backToSearch: 'Back to Search',
  errors: {
    'credentials-password': 'Mot de passe incorrect.',
    'credentials-code': 'Code de vérification incorrect.',
    'invalid-phone': 'Téléphone incorrect.',
    credentials: 'Login ou mot de passe incorrect.',
    'account-disabled': 'Compte inexistant.',
    'credentials-phone': 'Ce numéro de téléphone n\'existe pas. Vérifiez-le ou utilisez votre email.',
  },
  waitMessages: [
    'Belle journée, aujourd\'hui',
    'C\'est un peu plus long que ce que je pensais',
    'C\'est bientôt fini...',
    'Un peu de méditation pour patienter ?',
    'Ce n\'est pas une blague, il faut rester patient',
    'J\'imagine que les satellites sont bientôt en position',
    'Je manque d\'imagination pour vous faire patienter, et pourtant...',
    'Ne jamais remettre à demain ce qu\'on peut faire le jour même',
    'Plus on désire une chose, plus elle se fait attendre.',
    '"La folie, c\'est se comporter de la même manière et s\'attendre à un résultat différent." - Einstein',
    '"Rêver, c\'est le bonheur ; attendre, c\'est la vie." - Victor Hugo',
    '"J\'ai remarqué souvent que les gens qui sont en retard sont de bien meilleure humeur que ceux qui ont dû les attendre." - André Roussin',
    '"Tout vient à point à qui sait attendre." - Clément Marot',
  ],
};
